import React from "react";
import "./blog.css";
import BlogPost from "./blogpost.js";
import BlogDetails from './blogdetails.js';

import bp1img from "./blogs/blogpost1.png";
import bp2img from "./blogs/blogpost2.png";
import bp3img from "./blogs/alphafold2.jpg";
import bp4img from "./blogs/blogpost4.jpg";


import {HashRouter as Router,
  Switch,
  Route} from "react-router-dom";

export default function Blog() {

  return (
    <div className="blog_container">
    <Router>
      <Switch>
        <Route exact path ="/blog">
          <h1><b> Blog </b></h1>
          <BlogPost md={'https://resume-website-mds.s3.amazonaws.com/blogs/blogpost4.md'} image={bp4img} id={'blogpost4'}/>
          <BlogPost md={'https://resume-website-mds.s3.amazonaws.com/blogs/blogpost3.md'} image={bp3img} id={'blogpost3'}/>
          <BlogPost md={'https://resume-website-mds.s3.amazonaws.com/blogs/blogpost2.md'} image={bp2img} id={'blogpost2'}/>
          <BlogPost md={'https://resume-website-mds.s3.amazonaws.com/blogpost1.md'} image={bp1img} id={'blogpost1'}/>
        </Route>
        <Route path="/blog/blogpost1">
          <BlogDetails md={'https://resume-website-mds.s3.amazonaws.com/blogpost1.md'}/>
        </Route>
        <Route path="/blog/blogpost2">
          <BlogDetails md={'https://resume-website-mds.s3.amazonaws.com/blogs/blogpost2.md'}/>
        </Route>
        <Route path="/blog/blogpost3">
          <BlogDetails md={'https://resume-website-mds.s3.amazonaws.com/blogs/blogpost3.md'}/>
        </Route>
        <Route path="/blog/blogpost4">
          <BlogDetails md={'https://resume-website-mds.s3.amazonaws.com/blogs/blogpost4.md'}/>
        </Route>
      </Switch>
    </Router>
    </div>
  )
}
